import { useEffect, useState } from 'react';
import { fetchHandle } from '../../api/fetch';
import Hours from '../Hours/Hours';
import Objects from '../Objects/Objects';
import List from '../List/List';
import cls from './Content.module.css'
import Filter from '../Filter/Filter';
import ObjectPage from '../Objects/ObjectPage/ObjectPage';
import ModalWindow from '../ModalWindow/ModalWindow';
import AskWindow from '../AskWindow/AskWindow';
import Profile from '../Profile/Profile';

function Content(props) {
  useEffect(() => {
    // fetchHandle('GET', props.initGoods,'')
    // fetchHandle('GET', props.initTasks,'tasks')
    fetchHandle('GET', props.initUsers,'users')
    fetchHandle('GET', props.initObjects,'objects')
    fetchHandle('GET', props.initHours,'hours',undefined, '/' + props.weekNumber + '/' + props.app.userName + '/' + props.hours.currentYear)

    // fetchHandle('GET', props.setSereverNotice,'notice')
  }, [])
  let changeInput = (value) => {
    props.app.state === 'GOODS'
    ? props.changeValue(value)
    : props.changeTaskValue(value)
  }

  return (
    // <main style={printTheme} className={cls.wrapper}>
    <main  className={cls.wrapper}>
      <>
      {/* { props.app.state ==='GOODS'
          ? <List elements={props.goods.goods}
              notice={props.app.notice}

              appState={props.app.state}
              inputValue={props.goods.newProductinput}
              newProducts={props.newProducts}
              changeInput={changeInput}
              addNewProduct={props.addNewProduct}
              updateProduct={props.updateProduct}
              deleteProduct={props.deleteProduct}
              />
          : null} */}
      {props.app.state ==='TASKS'
          ? <List elements={props.tasks.tasks}
              notice={props.app.notice}

                appState={props.app.state}
                inputValue={props.tasks.newTaskInput}
                changeInput={changeInput}
                addNewProduct={props.addNewTask}
                newProducts={props.newTasks}
                updateProduct={props.updateTask}
                deleteProduct={props.deleteTask}
              />
          : null}
      {props.app.state ==='HOURS'
          ? <Hours hours={props.hours}
                    app={props.app}
                    addNewWorkItem={props.addNewWorkItem}
                    deleteWorkItem={props.deleteWorkItem}
                    weekNumber={props.weekNumber}
                    inputHandleWeek={props.inputHandleWeek}
                    objects={props.objects}
                    users={props.users}
          />
          : null}
      {props.app.state ==='REPORT'
          ? <Hours hours={props.hours}
                    app={props.app}
                    addNewWorkItem={props.addNewWorkItem}
                    deleteWorkItem={props.deleteWorkItem}
                    weekNumber={props.weekNumber}
                    inputHandleWeek={props.inputHandleWeek}
          />
          : null}
      {props.app.state ==='FILTER'
          ? <Filter
              filter={props.filter.filter}
              app={props.app}
              users={props.users.users}
              initFilter={props.initFilter}
              objects={props.objects}
            />
          : null}
      {props.app.state ==='OBJECTS'
          ? <Objects
          app={props.app}
          objects={props.objects}
          users={props.users}
          addNewObject={props.addNewObject}
          archiveObject={props.archiveObject}
          deleteUser={props.deleteUser}
          initCurrentUser={props.initCurrentUser}
          addSalaryInObjectSpending={props.addSalaryInObjectSpending}
          addNewUser={props.addNewUser}
          addUserInObject={props.addUserInObject}
          deleteUserFromObject={props.deleteUserFromObject}
          changeAppState={props.changeAppState}
          setCurrentObject={props.setCurrentObject}
          />
          : null}
      {props.app.state ==='PROFILE'
          ? <Profile
          app={props.app}
          users={props.users}
          setAskWindow={props.setAskWindow}
          addHistoryItem={props.addHistoryItem}
          />
          : null}
      {props.app.state ==='OBJECTPAGE'
          ? <ObjectPage
            app={props.app}
            objects={props.objects}
            salary={props.objects.salary}
            changeModalWindow={props.changeModalWindow}
            deleteSpendObject={props.deleteSpendObject}
            currentObject={props.objects.currentObject}
            setAskWindow={props.setAskWindow}
          />
          : null}
          {props.app.modalWindow 
            ? <ModalWindow 
                app={props.app}
                objects={props.objects}
                changeModalWindow={props.changeModalWindow}
                addSpendObject={props.addSpendObject}
                changeCurrentSpand={props.changeCurrentSpand}
                currentSpend={props.objects.currentSpend}
                setAskWindow={props.setAskWindow}
              /> 
            : null}
          {props.app.askWindow.isActive
            ?<AskWindow 
            obj={props.app.askWindow}
            setAskWindow={props.setAskWindow}
            />
            : null}
          
        </>
    </main>
  );
}

export default Content;
