import { getHoursAnding, getHoursAndingFI, getTimestampFromdayAndWeek, sortHoursbyWeeks, spendingReason } from "../assets/functions"

const ADD_NEW_OBJECT = 'ADD_NEW_OBJECT'
const ARCHIVE_OBJECT = 'ARCHIVE_OBJECT'
const INIT_OBJECTS = 'INIT_OBJECTS'
const ADD_SALARY_IN_OBJECT_SPENDING = 'ADD_SALARY_IN_OBJECT_SPENDING'
const ADD_USER_IN_OBJECT = 'ADD_USER_IN_OBJECT'
const SET_CURRENT_OBJECT = 'SET_CURRENT_OBJECT'
const ADD_SPEND_OBJECT = 'ADD_SPEND_OBJECT'
const DELETE_SPEND_OBJECT = 'DELETE_SPEND_OBJECT'
const CHANGE_CURRENT_SPEND = 'CHANGE_CURRENT_SPEND'
const SALARY = 33

let nameLS = localStorage.getItem('nameVkr') && localStorage.getItem('nameVkr') !== '' ?
  localStorage.getItem('nameVkr') :
  null

const initState = {
  objects:[],
  salary:SALARY,
  currentSpend:{
    id:new Date().getTime(),
    author:nameLS,
    reason:1,
    quantity:0,
    date:Math.round(new Date().getTime()/1000),
    comment:'',
    img:'',
  },
  currentObject:{
    adress: "some adress",
    archive: true,
    id: 0,
    nameObj: "some name",
    number: "some number",
    spending: [
        {id:143242323,reason:0,quantity:0, date:23214213421,text:'efesome text', img:'' }
    ],
    __v: 1,
    _id: "654e9991cbc8c1489e6000ee"
  }
}


const objectsReducer = (state = initState, action) => {  
    switch(action.type){
        case ADD_NEW_OBJECT:    
            return {...state,objects:[...state.objects,action.value]}
        case ARCHIVE_OBJECT:    
        let arr = state.objects.map((el,idx) => {
            if(+el.id === +action.value.id){
                el.archive = !el.archive
            }
            return el
        })

            return {...state,objects:[...arr]}
            
        case INIT_OBJECTS:    
            return {...state,objects:[...action.value]}

        case ADD_SALARY_IN_OBJECT_SPENDING: 
            const groupedData = sortHoursbyWeeks(action.value)
            let result = []
            for (const key in groupedData) {
                let sum = 0
                groupedData[key].map((el,idx) => {
                    sum += el.time
                }) 
                let totalSum = sum * SALARY
                result.push({
                    id:new Date().getTime(),
                    author:'Gerda App',
                    date:getTimestampFromdayAndWeek(0,key),
                    quantity:totalSum,
                    img:'',
                    reason:0,
                    comment:`неделя ${key} - ${sum} ${getHoursAnding(sum)}`,
                })
            }
            let addInCurrentObjectSpending = {...state.currentObject,spending:state.currentObject.spending.concat(result)}
            
            return {...state,currentObject:{...addInCurrentObjectSpending}}

        case SET_CURRENT_OBJECT:   
        let res = {}
        let currentObject = [...state.objects]
        for (let i = 0; i < currentObject.length; i++) {
            if(+currentObject[i].id === +action.value){
                res = currentObject[i]
            }
        }
    
            return {...state,currentObject:{...res}}

        case ADD_USER_IN_OBJECT:   
            let newObjects = state.objects.map((el) => {
            if(el.id === action.value.obj.id){
                el.users.push(action.value.user)
                }
                return el
            }) 
            return {...state,objects:[...newObjects]}

        case ADD_SPEND_OBJECT:   
            let newSpendingArr = [...state.currentObject.spending]
            newSpendingArr.push({...state.currentSpend})
            return {...state, currentObject:{...state.currentObject,spending:[...newSpendingArr]}}
            
        case DELETE_SPEND_OBJECT:  
            let o = state.currentObject.spending
        let indexToRemove = o.findIndex(obj => obj.id == action.value.spid);
        o.splice(indexToRemove, 1);
            return {...state, currentObject:{...state.currentObject, spending:[...o]}}

        case CHANGE_CURRENT_SPEND:   
            return {...state, currentSpend:{...action.value,id:new Date().getTime()}}
        default:
            return state
    }
}
export const addNewObject = (value) => ({type:ADD_NEW_OBJECT, value})
export const archiveObject = (value) => ({ type:ARCHIVE_OBJECT, value})
export const initObjects = (value) => ({ type:INIT_OBJECTS, value})
export const addSalaryInObjectSpending = (value) => ({ type:ADD_SALARY_IN_OBJECT_SPENDING, value})
export const setCurrentObject = (value) => ({ type:SET_CURRENT_OBJECT, value})
export const addUserInObject = (value) => ({ type:ADD_USER_IN_OBJECT, value})
export const addSpendObject = (value) => ({ type:ADD_SPEND_OBJECT, value})
export const deleteSpendObject = (value) => ({ type:DELETE_SPEND_OBJECT, value})
export const changeCurrentSpand = (value) => ({ type:CHANGE_CURRENT_SPEND, value})
export default objectsReducer