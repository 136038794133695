const CHANGE_APP_STATE = 'CHANGE_APP_STATE'
const SET_USER_NAME = 'SET_USER_NAME'
const SET_SERVER_NOTICE = 'SET_SERVER_NOTICE'
const CHANGE_MODAL_WINDOW = 'CHANGE_MODAL_WINDOW'
const SET_ASK_WINDOW = 'SET_ASK_WINDOW'
const SET_LANG = 'SET_LANG'


let nameLS = localStorage.getItem('nameVkr') && localStorage.getItem('nameVkr') !== '' ?
  localStorage.getItem('nameVkr') :
  null
let langLS = localStorage.getItem('langVkr') && localStorage.getItem('langVkr') !== '' ?
  localStorage.getItem('langVkr') :
  'FI'

let localGoodsID = localStorage.getItem('goodsVkrID') || 0
let localTasksID = localStorage.getItem('tasksVkrID') || 0

const initState = {
  state: nameLS === 'Olga' ? 'FILTER' : 'HOURS', //GOODS, TASKS, HOURS, EXAMPLE, OBJECTS, FILTER, OBJECTPAGE, PROFILE
  userName: nameLS || '',
  modalWindow:false,
  lang:langLS,
  notice:{
    localGoodsID,
    localTasksID,
    serverGoodsID:0,
    serverTasksID:0,
  },
  askWindow:{
    isActive:false,
    callback:() => alert('test'),
    text:'',
    id:0
  }
}


const appReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_APP_STATE:
      return {...state, state: action.value}

    case SET_USER_NAME:
        let stateOfApp = action.value.name === 'Ants' 
                      || action.value.name === 'Elena'
                      || action.value.name === 'Olga' ? 'OBJECTS' : 'HOURS'
        localStorage.setItem('nameVkr', action.value.name)
      return {...state, userName: action.value.name, state:stateOfApp}

    case SET_LANG:
      let lang = action.value.lang === 'FI' ? 'RU' : 'FI'
        console.log('action.value.lang: ', lang);
        localStorage.setItem('langVkr', lang)
      return {...state,lang}

    case SET_SERVER_NOTICE:
      return {...state, notice:{...state.notice,serverGoodsID:action.value[0].serverGoodsID, serverTasksID:action.value[0].serverTasksID,}}
    
    case CHANGE_MODAL_WINDOW:
        let rsult = state.modalWindow
      return {...state, modalWindow:!rsult}

    case SET_ASK_WINDOW:
      return {...state, askWindow:{...action.value}}

    default:
      return state
  }
}
export const changeAppState = (value) => ({type: CHANGE_APP_STATE,value})
export const setSereverNotice = (value) => ({type: SET_SERVER_NOTICE, value})
export const setUserName = (value) => ({type: SET_USER_NAME,value})
export const setLang = (value) => ({type: SET_LANG,value})
export const changeModalWindow = (value) => ({type: CHANGE_MODAL_WINDOW,value})
export const setAskWindow = (value) => ({type: SET_ASK_WINDOW,value})
export default appReducer