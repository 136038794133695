import moment from "moment"
import { getCurrentWeek } from "../assets/functions"

const ADD_NEW_WORK_ITEM = 'ADD_NEW_WORK_ITEM'
const DELETE_WORK_ITEM = 'DELETE_WORK_ITEM'
const INIT_HOURS = 'INIT_HOURS'
const SET_INPUT_HANDLE_WEEK = 'SET_INPUT_HANDLE_WEEK'

const initState = {
  currentNumberWeek:getCurrentWeek(),
  currentYear:moment().isoWeek(1).startOf('year').valueOf() / 1000,
  inputHandleWeek:getCurrentWeek(),
  week:[
        //   {workName:'leikea', time:2,id:1, weekNumber:11, dayOfWeek:1},
  ]
}


const hoursReducer = (state = initState, action) => {  
    switch(action.type){
        case ADD_NEW_WORK_ITEM:    
            return {...state,week:[...state.week,action.value]}
        case DELETE_WORK_ITEM:    
            return {...state,week:state.week.filter((item) => item.id !== action.value.id)}
        case INIT_HOURS:    
            return {...state,week:action.value}
        case SET_INPUT_HANDLE_WEEK:
            return {...state,inputHandleWeek:+action.value}
        default:
            return state
    }
}
export const addNewWorkItem = (value) => ({type:ADD_NEW_WORK_ITEM, value})
export const deleteWorkItem = (value) => ({ type:DELETE_WORK_ITEM, value})
export const initHours = (value) => ({ type:INIT_HOURS, value})
export const setInputHandleWeek = (value) => ({ type:SET_INPUT_HANDLE_WEEK, value})
export default hoursReducer