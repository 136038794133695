import Auth from '../Auth/Auth';
import ContentContainer from '../Content/ContentContainer';
import HeaderContainer from '../Header/HeaderContainer';
import cls from './App.module.css'
function App(props) {
  return (
    <div className="App">
      
      {props.app.userName !== ''
      ? <>
          <HeaderContainer/>
          <ContentContainer/>
        </>
      : <Auth setUserName={props.setUserName}/>
      }
      
    </div>
  );
}

export default App;
