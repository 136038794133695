import { setLocalStoreNotice } from '../../assets/functions';
import AddProduct from '../AddProduct/AddProduct';
import Product from '../Product/Product';
import cls from './List.module.css'

function List(props) {
  setLocalStoreNotice(props.appState,props.notice)


  let reverseArr = [...props.elements].reverse()
  let output = reverseArr.map((element) => {
    return <Product key={element.id} 
                    product={element}
                    appState={props.appState}
                    updateProduct={props.updateProduct}
                    deleteProduct={props.deleteProduct}
            />
  })

  return (
    <div  className={cls.wrapper}>
      <AddProduct 
        appState={props.appState}
        newProducts={props.newProducts}
        changeInput={props.changeInput}
        newProductinput={props.inputValue}
        addNewProduct={props.addNewProduct}
      />
      <ul >
      {output}
      </ul>
    </div>
  );
}

export default List;
