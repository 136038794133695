import {
    getUserByID
} from "../assets/functions"

const ADD_NEW_USER = 'ADD_NEW_USER'
const DELETE_USER = 'DELETE_USER'
const INIT_USERS = 'INIT_USERS'
const INIT_CURRENT_USER = 'INIT_CURRENT_USER'
const ADD_HISTORY_ITEM = 'ADD_HISTORY_ITEM'



const initState = {
    users: [],
    currentUser: {
        id: 1699958425807,
        name: "",
        password: "",
        phone: "",
        surname: "",
        clothes: {
            shirt: '',
            pants: '',
            shose: '',
            history: [{
                id: 1232421,
                type: 'Штаны',
                date: 1711905102,
                size: 'L'
            }]
        }
    }
}


const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_NEW_USER:
            return {
                ...state, users: [...state.users, action.value]
            }
            case DELETE_USER:
                return {
                    ...state, users: state.users.filter((item) => item.id !== action.value.id)
                }

            case INIT_USERS:
                let sortArr = [...action.value]
                sortArr.sort((a, b) => a.surname > b.surname ? 1 : -1)
                return {
                    ...state, users: [...sortArr]
                }
            case INIT_CURRENT_USER:
                let curUser = getUserByID([...state.users], action.value)
                return {
                    ...state, currentUser: curUser
                }
            case ADD_HISTORY_ITEM:
                let newHistory = [...state.currentUser.clothes.history,action.value.history]
                console.log('action.history: ', action.value.history);
                console.log('newHistory: ', newHistory);
                return {
                    ...state,currentUser: {...state.currentUser, clothes:{...state.currentUser.clothes,history:[...newHistory]}}
                }
            default:
                return state
    }
}
export const addNewUser = (value) => ({
    type: ADD_NEW_USER,
    value
})
export const deleteUser = (value) => ({
    type: DELETE_USER,
    value
})
export const initUsers = (value) => ({
    type: INIT_USERS,
    value
})
export const initCurrentUser = (value) => ({
    type: INIT_CURRENT_USER,
    value
})
export const addHistoryItem = (value) => ({
    type: ADD_HISTORY_ITEM,
    value
})
export default usersReducer