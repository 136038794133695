import Form from '../Form/Form';
import cls from './ModalWindow.module.css'


function ModalWindow(props) {
  return <div className={cls.wrapper}>
              <Form 
                app={props.app}
                objects={props.objects}
                changeModalWindow={props.changeModalWindow}
                addSpendObject={props.addSpendObject}
                changeCurrentSpand={props.changeCurrentSpand}
                currentSpend={props.currentSpend}
                setAskWindow={props.setAskWindow}
              />
          </div>
 
}

export default ModalWindow;
