import { fetchHandle } from '../../api/fetch'
import cls from './Product.module.css'

function Product(props) {
  let classStyle = props.product.status === 'pending' ? cls.wrapper : cls.wrapper + ' ' + cls.done

  let url = props.appState === 'GOODS' ? '' : 'tasks'

  let clickHandleDone = () => {
    fetchHandle('PUT', props.updateProduct, url, props.product)
  }
  let clickHandleDelete = () => {
    fetchHandle('DELETE', props.deleteProduct, url, props.product)
  }
  return (
  <li className={classStyle}>
    <span className={cls.name}>{props.product.name}</span>
    <div className={cls.iconWrapper}>
      <div className={cls.btn} onClick={clickHandleDelete}>
        <span>&#128465;</span>
      </div>
      <div className={cls.btn} onClick={clickHandleDone}>
        <span>&#10003;</span>
      </div>
    </div>
    
  </li>
  );
}

export default Product;
