import { fetchHandle } from '../../api/fetch';
import {  getCurrentYear, getTotalHoursForWeek, WORDS } from '../../assets/functions';
import cls from './HoursHeader.module.css'

function HoursHeader(props) {
  // let printTheme = props.app.userName === 'Olga' ? {filter:'opacity(0.5) grayscale(1)'} : null
  return (
    // <div style={printTheme} className={cls.wrapper} >
    <div  className={cls.wrapper} >
      <div className={cls.wrapperItem}> {WORDS.week[props.app.lang]}: 
        <div className={cls.weekWrapper}>
          <input onChange={(e) => {
            props.setInputHandleWeek(e.currentTarget.value)
            fetchHandle('GET', props.initHours,'hours', undefined, '/' + e.currentTarget.value + '/' + props.userName + '/' + props.currentYear)
            }} className={cls.input} type="number" defaultValue={props.weekNumber}/>
             <span className={cls.year}>{WORDS.year[props.app.lang]}: {getCurrentYear()}</span>
        </div>
        {/* <div onClick={() => {props.changeAppState('EXAMPLE')}} className={cls.exapmleBTN}>Образец</div> */}
      </div>
       
        <span className={cls.wrapperItem}>{WORDS.total[props.app.lang]} {getTotalHoursForWeek(props.week)} </span>
    </div>
  );
}

export default HoursHeader;
