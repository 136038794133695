import HeaderButtons from '../HeaderButtons/HeaderButtons';
import HoursHeader from '../HoursHeader/HoursHeader';
import cls from './Header.module.css'
import PdfViewerComponent from '../PDF/PDF.jsx';
import { WORDS } from '../../assets/functions.js';



function Header(props) {
  console.log('props: ', props);

  // let printTheme = props.app.userName === 'Olga' ? {filter:'opacity(0.5) grayscale(1)'} : null

  const removeName = () => {
    props.setUserName({ name: '' })
  }
  let output
  switch (props.app.state) {
    case 'GOODS':
      output = 'Требуется:'
      break;
    case 'TASKS':
      output = 'Задачи:'
      break;
    case 'HOURS':
      output = WORDS.hours[props.app.lang]
      break;
    case 'REPORT':
      output = WORDS.report[props.app.lang]
      break;
    case 'OBJECTS':
      output = WORDS.objects[props.app.lang]
      break;
    case 'FILTER':
      output = WORDS.filter[props.app.lang]
      break;
    default:
      output = ''
  }
  return (
    <>
      {/* <PdfViewerComponent
				document={"Document.pdf"}
			/> */}
      {/* <header style={printTheme} className={cls.wrapper}> */}
      <header className={cls.wrapper}>
        <span className={cls.heading}>
          {output}
        </span>
        <div className={cls.nameWrapper}>
          <div className={cls.name}
            onClick={removeName}>
            {props.app.userName}
          </div>
          {/* <select className={cls.languageSelect} name="" id="">
          <option value="">ru</option>
          <option value="">fi</option>
        </select> */}
        </div>
        <HeaderButtons
          app={props.app}
          users={props.users}
          notice={props.app.notice}
          setLang={props.setLang}
          initCurrentUser={props.initCurrentUser}
          changeAppState={props.changeAppState}
          userName={props.app.userName}
        />
      </header>
      {props.app.state === 'HOURS'
        ? <HoursHeader week={props.week}
          changeAppState={props.changeAppState}
          app={props.app}
          currentYear={props.currentYear}
          setInputHandleWeek={props.setInputHandleWeek}
          userName={props.app.userName}
          weekNumber={props.weekNumber}
          initHours={props.initHours}
        />
        : null}
    </>
  );
}

export default Header;
