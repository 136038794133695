
import { useState } from 'react';
import cls from './Profile.module.css'
import PORT from '../../api/config';
import { fetchHandle } from '../../api/fetch';
import AskWindow from '../AskWindow/AskWindow';
import { WORDS, getDateFromTimestamp, getDateInputValueFromTimestamp, isAdmin } from '../../assets/functions';

function Profile(props) {
  let admin = isAdmin(props.app.userName)

  const [historyClothes, setHistoryClothes] = useState({
    id: new Date().getTime() / 1000,
    type: 'Футболка',
    date: new Date().getTime() / 1000,
    size: ''
  })

  let USER = props.users.currentUser
  const [clothes, setClothes] = useState({ ...USER.clothes })
  let historyOutput
  if(USER.clothes.history.length !== 0){
     historyOutput = USER.clothes.history.map((e, idx) => {
      return <li key={idx} className={`${cls.liHistory}`}>
        <span className={cls.spanHistory}>{getDateFromTimestamp(e.date)}</span>
        <span className={cls.spanHistory}>{e.type}</span>
        <span className={cls.spanHistory}>{e.size}</span>
        <span className={cls.spanHistory}>TO DO</span>
        <div className={`${cls.spanHistory} ${cls.button}`} onClick={() => { pressOnCross('lol') }}>
          <span className={cls.iconCross}></span>
          <div className={`${cls.iconCross} ${cls.iconCross_rotate}`}>
          </div>
        </div>
      </li>
    })
  }
  
  const changeInputsHistory = (e) => {
    let input = e.currentTarget.getAttribute('history')
    if (input === 'date') {
      let date = new Date(e.currentTarget.value).getTime()/1000
      setHistoryClothes({ ...historyClothes, 'date': date})
    }
    if (input === 'type') {
      setHistoryClothes({ ...historyClothes, 'type': e.currentTarget.value })
    }
    if (input === 'size') {
      setHistoryClothes({ ...historyClothes, 'size': e.currentTarget.value })
    }
  }
  const clickHistoeyAdd = () =>{
    props.addHistoryItem({history:historyClothes,userId:USER.id})
  }
  const changeClothesInput = (e) => {
    let input = e.currentTarget.getAttribute('kind')
    if (input === 'shirt') {
      setClothes({ ...clothes, 'shirt': e.currentTarget.value })
    }
    if (input === 'pants') {
      setClothes({ ...clothes, 'pants': e.currentTarget.value })
    }
    if (input === 'shose') {
      setClothes({ ...clothes, 'shose': e.currentTarget.value })
    }
  }
  const saveClothes = (e) => {
    let id = e.currentTarget.getAttribute('id')
    fetchHandle('POST', () => { }, 'clothes', { id, clothes })
  }
  const pressOnCross = (e) => {
    props.setAskWindow({
      isActive: true,
      callback: () => alert('delete'),
      text: 'Вы точно хотите удалить эту запись?',
      id: new Date().getDate()
    }
    )
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.infoWrapper}>
        <div className={cls.imgWrapper}>
          <div className={cls.placeholder}></div>
          {/* <img src="" alt="" /> */}
        </div>
        <div className={cls.info}>
          <div className={cls.inputWrapper}>
            <span className={cls.label}>{WORDS.name[props.app.lang]}:</span>
            <input className={cls.input} type="text" value={USER.name} readOnly />
          </div>
          <div className={cls.inputWrapper}>
            <span className={cls.label}>{WORDS.surname[props.app.lang]}:</span>
            <input className={cls.input} type="text" value={USER.surname} readOnly />
          </div>
          <div className={cls.inputWrapper}>
            <span className={cls.label}>{WORDS.phone[props.app.lang]}:</span>
            <input className={cls.input} type="text" value={USER.phone} readOnly />
          </div>
          <div className={cls.clothes}>
            <div className={cls.clothesInputs}>
              <div className={cls.inputWrapper}>
                <span className={cls.clothesLabel}>{WORDS.shirt[props.app.lang]}</span>
                <input className={`${cls.input} ${cls.clothesInput}`}
                  kind="shirt"
                  type="text"
                  onChange={(e) => { changeClothesInput(e) }}
                  value={clothes.shirt}
                  readOnly={USER.name === props.app.userName ? false : true}
                />
              </div>
              <div className={cls.inputWrapper}>
                <span className={cls.clothesLabel}>{WORDS.pants[props.app.lang]}</span>
                <input className={`${cls.input} ${cls.clothesInput}`}
                  kind="pants"
                  type="text"
                  onChange={(e) => { changeClothesInput(e) }}
                  value={clothes.pants}
                  readOnly={USER.name === props.app.userName ? false : true}
                />
              </div>
              <div className={cls.inputWrapper}>
                <span className={cls.clothesLabel}>{WORDS.shose[props.app.lang]}</span>
                <input className={`${cls.input} ${cls.clothesInput}`}
                  kind="shose"
                  type="text"
                  onChange={(e) => { changeClothesInput(e) }}
                  value={clothes.shose}
                  readOnly={USER.name === props.app.userName ? false : true}
                />
              </div>
            </div>
            {USER.name === props.app.userName ?
            <div id={USER.id} onClick={(e) => { saveClothes(e) }} className={cls.buttonSave}>
            {WORDS.save[props.app.lang]}
          </div>
          :null
            }
            
          </div>  
          {/*TO DO */}
           {/*<div className={cls.clothesHistory}>

            <ul>
              <li className={`${cls.liHistory} ${cls.liHistoryHeader}`}>
                <span className={cls.spanHistory}>Дата</span>
                <span className={cls.spanHistory}>Одежда</span>
                <span className={cls.spanHistory}>Размер</span>
                <span className={cls.spanHistory}>Выдача</span>
                <span className={cls.spanHistory}> </span>
              </li>
              {historyOutput}
              {admin ?
                <li className={`${cls.liHistory} ${cls.liHistoryHeader}`}>
                  <input className={cls.liHistoryAdd}
                    history='date'
                    type="date"
                    onChange={(e) => { changeInputsHistory(e) }}
                    value={getDateInputValueFromTimestamp(historyClothes.date)} />
                  <select value={historyClothes.type}
                    history='type'
                    onChange={(e) => { changeInputsHistory(e) }}
                    className={cls.liHistoryAdd}>
                    <option value="Футболка">Футболка</option>
                    <option value="Штаны">Штаны</option>
                    <option value="Обувь">Обувь</option>
                  </select>
                  <input
                    history='size'
                    onChange={(e) => { changeInputsHistory(e) }}
                    value={historyClothes.size}
                    className={cls.liHistoryAdd}
                    type="text" />
                  <div onClick={()=>{clickHistoeyAdd()}} className={cls.liHistoryAddBtn}>
                    Добавить
                  </div>
                </li>
                : null}

            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
