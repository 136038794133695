import {
    connect
} from 'react-redux'
import Content from './Content.jsx'
import {
    changeValue,
    addNewProduct,
    initGoods,
    updateProduct,
    deleteProduct
} from '../../redux/goodsReducer.js'
import {
    initTasks,
    deleteTask,
    updateTask,
    changeTaskValue,
    addNewTask
} from '../../redux/tasksReducer.js'
import {
    addNewWorkItem,
    deleteWorkItem,
    initHours
} from '../../redux/hoursReducer.js'
import {
    changeAppState,
    setSereverNotice,
    changeModalWindow,
    setAskWindow
} from '../../redux/appReducer.js'
import {
    addNewObject,
    archiveObject,
    initObjects,
    setCurrentObject,
    addUserInObject,
    addSalaryInObjectSpending,
    addSpendObject,
    deleteSpendObject,
    changeCurrentSpand
} from '../../redux/objectsReducer.js'
import {
    initUsers,
    addNewUser,
    deleteUser,
    initCurrentUser,
    addHistoryItem
} from '../../redux/usersReducer.js'
import {
    initFilter
} from '../../redux/filterReducer.js'


let mapStateToProps = (state) => {
    return {
        goods: state.goods,
        newProducts: state.goods.newProductinput,
        newTasks: state.tasks.newTaskInput,
        app: state.app,
        objects: state.objects,
        users: state.users,
        tasks: state.tasks,
        hours: state.hours,
        filter: state.filter,
        weekNumber: state.hours.currentNumberWeek,
        inputHandleWeek: state.hours.inputHandleWeek,

    }
}
let mapDispatchToProps = (dispatch) => {
    return {
        ///--------------GOODS-------------
        changeValue: (value) => {
            dispatch(changeValue(value))
        },
        addNewProduct: (product) => {
            dispatch(addNewProduct(product))
        },
        initGoods: (goods) => {
            dispatch(initGoods(goods))
        },
        updateProduct: (product) => {
            dispatch(updateProduct(product))
        },
        deleteProduct: (product) => {
            dispatch(deleteProduct(product))
        },
        ///--------------TASKS-------------

        initTasks: (tasks) => {
            dispatch(initTasks(tasks))
        },
        updateTask: (product) => {
            dispatch(updateTask(product))
        },
        deleteTask: (product) => {
            dispatch(deleteTask(product))
        },
        changeTaskValue: (value) => {
            dispatch(changeTaskValue(value))
        },
        addNewTask: (task) => {
            dispatch(addNewTask(task))
        },
        ///--------------APP-------------

        changeAppState: (value) => {
            dispatch(changeAppState(value))
        },
        setSereverNotice: (value) => {
            dispatch(setSereverNotice(value))
        },
        changeModalWindow: (value) => {
            dispatch(changeModalWindow(value))
        },
        setAskWindow: (value) => {
            dispatch(setAskWindow(value))
        },
        ///--------------HOURS-------------

        addNewWorkItem: (value) => {
            dispatch(addNewWorkItem(value))
        },
        deleteWorkItem: (value) => {
            dispatch(deleteWorkItem(value))
        },
        initHours: (value) => {
            dispatch(initHours(value))
        },
        ///--------------FILTER-------------

        initFilter: (value) => {
            dispatch(initFilter(value))
        },
        ///--------------OBJECTS-------------

        addNewObject: (value) => {
            dispatch(addNewObject(value))
        },
        archiveObject: (value) => {
            dispatch(archiveObject(value))
        },
        initObjects: (value) => {
            dispatch(initObjects(value))
        },
        addSalaryInObjectSpending: (value) => {
            dispatch(addSalaryInObjectSpending(value))
        },
        setCurrentObject: (value) => {
            dispatch(setCurrentObject(value))
        },
        addUserInObject: (value) => {
            dispatch(addUserInObject(value))
        },
        addSpendObject: (value) => {
            dispatch(addSpendObject(value))
        },
        deleteSpendObject: (value) => {
            dispatch(deleteSpendObject(value))
        },
        changeCurrentSpand: (value) => {
            dispatch(changeCurrentSpand(value))
        },
        ///--------------USERS-------------

        initUsers: (value) => {
            dispatch(initUsers(value))
        },
        initCurrentUser: (value) => {
            dispatch(initCurrentUser(value))
        },
        addNewUser: (value) => {
            dispatch(addNewUser(value))
        },
        deleteUser: (value) => {
            dispatch(deleteUser(value))
        },
        addHistoryItem: (value) => {
            dispatch(addHistoryItem(value))
        },


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Content)