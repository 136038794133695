import PORT from "./config";


export const fetchHandle = (method, callback, url, dataObj, params = '') => {
  fetch(PORT + url + params, {
    method: method,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body:JSON.stringify(dataObj)
  })
  .catch((err)=>{
    console.log(err)
  })
  .then((response) => {
    return response.text()
  })
  .then((data) => {
    method === "GET"
    ? callback(JSON.parse(data))
    : callback(dataObj)
  })
}