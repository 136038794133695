import { useState } from 'react';
import { getDateInputValueFromTimestamp, getTimestampFromInputValue, spendingReason } from '../../assets/functions';
import cls from './Form.module.css'
import { fetchHandle } from '../../api/fetch';


function Form(props) {
  
    let currentSpend = props.objects.currentSpend
  const [formState, setFormState] = useState(props.currentSpend)
  let  changeInput =  (inputName, e) => {
    let newFormState
    if(inputName === 'date'){
      newFormState = {...formState, date:getTimestampFromInputValue(e.currentTarget.value)}
      
    }
    if(inputName === 'reason'){
      newFormState = {...formState, reason:e.currentTarget.value}
    }
    if(inputName === 'comment'){
      newFormState = {...formState, comment:e.currentTarget.value}
    }
    if(inputName === 'quantity'){
      newFormState = {...formState, quantity:+e.currentTarget.value}
    }
    if(inputName === 'image'){
      newFormState = {...formState, image:e.target.files[0]}
    }
    props.changeCurrentSpand(newFormState)
    setFormState(newFormState)
  }
  let pressAddSpend = () => {
    fetchHandle('POST', props.addSpendObject, 'spending',{spending:currentSpend, objID:props.objects.currentObject.id})
    props.changeModalWindow()
  }

  

  let optionSelect = spendingReason.map((el,idx) => {
    let disabled = idx === 0 ? true:false // block cjoose salary option
     return <option disabled={disabled} key={idx} value={idx}>{el}</option>
  })
  return <div className={cls.wrapper}>
    <div className={cls.header}>
      <span>Добавить расход:</span>
      <span className={cls.cross}
            onClick={() => {props.changeModalWindow()}}>
        X
      </span>
    </div>
    <form className={cls.form_wrapper} enctype="multipart/form-data">
      <div className={cls.inputWrapper}>
        <label>Дата:</label>
        <input onChange={(e) => {changeInput('date',e)}} type="date"  value={getDateInputValueFromTimestamp(currentSpend.date)}/>
      </div>
      <div className={cls.inputWrapper}>
        <label> Причина:</label>
        <select onChange={(e) => {changeInput('reason',e)}} value={currentSpend.reason}>
          {optionSelect}
        </select>
      </div>
      <div className={cls.inputWrapper}>
        <label>Комментарий:</label>
        <input type="text" onChange={(e) => {changeInput('comment',e)}} value={currentSpend.comment}/>
      </div>
      <div className={cls.inputWrapper}>
        <label>Колличество:</label>
        <input type="number" onChange={(e) => {changeInput('quantity',e)}}  value={currentSpend.quantity}/>
      </div>
      <div  className={cls.inputWrapper}>
        <label>Фото:</label>
        <input type="file"  disabled name='image' onChange={(e) => {changeInput('image',e)}}  />
      </div>
      <div  className={cls.buttonWrapper}>
        <button onClick={() => {pressAddSpend()}} className={cls.button}>+ Сохранить</button>
      </div>
      
    </form>
  </div>
 
}

export default Form;
