import { fetchHandle } from '../../api/fetch'
import { setNewProduct } from '../../assets/functions';
import cls from './AddProduct.module.css'

function AddProduct(props) {

  let url = props.appState === 'GOODS' ? '' : 'tasks'
  
  let onClickHandler = () => {
    fetchHandle('POST', props.addNewProduct, url, setNewProduct(props.newProducts))
    
  }
 return <form className={cls.wrapper}>
          <input onChange={(event) => props.changeInput(event.currentTarget.value)} 
                  placeholder='Что то нужно на объект?' 
                  className={cls.input} 
                  type="text" 
                  value={props.newProductinput}
                  />
          <div className={cls.button} 
                onClick={onClickHandler}
          >
            <span>Отправить</span>
          </div>
        </form>
}

export default AddProduct;
