import cls from './AskWindow.module.css'


function AskWindow(props) {
  let obj = props.obj

  return <div className={cls.wrapper}>
    <div className={cls.form}>
      <div className={cls.header}> {obj.text}</div>
      <div className={cls.body}>
        <div className={cls.btn} onClick={() => {
          obj.callback()
          props.setAskWindow({ isActive: false })
        }
        }>Да</div>
        <div className={cls.btn} onClick={() => props.setAskWindow({ isActive: false })}>Нет</div>
      </div>
    </div>
  </div>

}

export default AskWindow;
