import List from './List/List';
import cls from './Objects.module.css';
import OneObject from './OneObject/OneObject';

function Objects(props) {
  // let output = props.objects.objects.map((el,idx) =>{
  //   return <OneObject 
  //             addUserInObject={props.addUserInObject}
  //             deleteUserFromObject={props.deleteUserFromObject}
  //             object={el} 
  //             key={idx}
  //             users={props.users}/>

  // })
  return (
    <div className={cls.wrapper}>
      <List 
        app={props.app}
        objects={props.objects}
        users={props.users}
        addNewObject={props.addNewObject}
        archiveObject={props.archiveObject}
        initCurrentUser={props.initCurrentUser}
        deleteUser={props.deleteUser}
        addNewUser={props.addNewUser}
        changeAppState={props.changeAppState}
        setCurrentObject={props.setCurrentObject}
        addSalaryInObjectSpending={props.addSalaryInObjectSpending}
        />
      {/* <ul>
        {output}
      </ul> */}
    </div>
  );
}

export default Objects;
