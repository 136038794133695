import {connect} from 'react-redux'
import App from './App.jsx'
import { setUserName } from '../../redux/appReducer.js'


let mapStateToProps = (state) => {
    return {...state}
}
let mapDispatchToProps = (dispatch) => {
    return {
        setUserName: (value) => {
            dispatch(setUserName(value))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)