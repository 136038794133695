import { useState } from 'react';
import PORT from '../../api/config';
import cls from './Auth.module.css'

function Auth(props) {
  const[userName, setUserName] = useState('')
  const[userPass, setUserPass] = useState('')

  const userNameChangeHandler = (e) => {
    setUserName(e.currentTarget.value);
  }
  const userPassChangeHandler = (e) => {
    setUserPass(e.currentTarget.value);
  }
   const authOnClickHandler = () => {
     fetch(PORT + 'auth', {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      body:JSON.stringify({name:userName, pass:userPass})
    })
    .catch((err)=>{
      console.log(err)
    })
    .then((response) => {
      if(response.status === 200){
        return response.text()
      }
      
    }).then(data => {
      props.setUserName(JSON.parse(data));

    })
  }

  return (
    <div className={cls.wrapper}>
      <form className={cls.form}>
        <div className={cls.header}>
          <span>Авторизоваться</span>
        </div>
        <div className={cls.body}>
          <label htmlFor="name">Логин:</label>
          <input id='name' 
                  value={userName} 
                  type="text" 
                  onChange={(e) => {userNameChangeHandler(e)}}
                  />
          <label htmlFor="pass">Пароль:</label>
          <input id='pass' 
                  value={userPass} 
                  type="password" 
                  onChange={(e) => {userPassChangeHandler(e)}}
                  />
          <div className={cls.button}
                  onClick={authOnClickHandler}
                  >
            Отправить
          </div>
        </div>
      </form>
    </div>
  );
}

export default Auth;
