import moment from 'moment';
import { getDateInputValueFromTimestamp, getHoursAnding, getHoursAndingFI, spendingReason } from '../../../assets/functions';
import cls from './ObjectPage.module.css'
import { fetchHandle } from '../../../api/fetch';


function  ObjectPage(props) {
  let obj = props.currentObject
  let pressOnAddSpending = () => {
    props.changeModalWindow()
  }
  let pressOnDeleteSpending = (e) => {
    let spid = e.currentTarget.getAttribute('spendingid')
    let objid =  e.currentTarget.getAttribute('objid')
    props.setAskWindow({
      isActive:true,
      callback:() => fetchHandle('DELETE', props.deleteSpendObject, 'spending', {spid:spid, objid:objid}),
      text:'Вы точно хотите удалить трату?',
      id:new Date().getDate()
    })

   
  }

  let arrSpendingCounter = [0,0,0,0,0,0] // create arr with zeros
  
  
  let spendingItems = obj.spending.map((el,idx) => {
      arrSpendingCounter[el.reason] = arrSpendingCounter[el.reason] + +el.quantity.toFixed(2) // add quantity of number equal reason
    return <li key={el.id+idx} className={`${cls.body_li} ${idx%2?cls.li_grey:null}`}>
              <span>{idx+1}.)</span>
              <span>{moment(el.date*1000).format('DD.MM')}</span>
              <span>{spendingReason[el.reason]}</span>
              <span>{el.comment}</span>
              <span>{el.author}</span>
              <span>{+el.quantity.toFixed(2)}€</span>
              <span>фото</span>
              
              {el.reason !== 0 && el.author === props.app.userName // stop add editBtn because user can't change it
              ? <div objid={obj.id} spendingid={el.id} className={cls.button} onClick={(e) => {pressOnDeleteSpending(e)}}>
                  <span className={cls.iconCross}></span>
                  <span className={`${cls.iconCross} ${cls.iconCross_rotate}`}></span>
                </div>
              : null
              }
            </li>
  })
  let countingOutput = []
  let totalSum = 0
  countingOutput.push(<li key={'btn'} className={cls.add_spending_li}
                          onClick={() => {pressOnAddSpending()}}
                      >
                        Добавить расход +
                      </li>)
  for (let i = 0; i < arrSpendingCounter.length; i++) { // add in output <li> with result
    if(arrSpendingCounter[i] !== 0){
      let countOfHours = ''
      if(i === 0){
        countOfHours = +arrSpendingCounter[i] / props.salary
        countOfHours = ' ' + countOfHours + ' ' + props.app.lang === 'FI' ? getHoursAndingFI(countOfHours) : getHoursAnding(countOfHours)
      }
      totalSum += +arrSpendingCounter[i]
      countingOutput.push(<li key={`${i}${cls.reason_li}`} className={cls.reason_li}>
        {spendingReason[i]}{countOfHours} - {+arrSpendingCounter[i].toFixed(2)}€
        </li>)
    }
  }
  countingOutput.push(<li key={totalSum} className={cls.reason_li}>Всего - {totalSum.toFixed(2)}€</li>)
  let outputItems = spendingItems.concat(countingOutput)

  return (
    <div  className={cls.wrapper} >
      <div className={cls.header}>
          <div className={cls.imageWrapper}>
            img
          </div>
          <div className={cls.headerInfo}>
            <h2>{obj.nameObj}</h2>
            <span>Адрес: {obj.adress}</span>
            {obj.number
            ?<span className={cls.number}>Номер объекта: {obj.number}</span>
            : null}
          </div>
      </div>
      <div className={cls.spendingWrapper}>
        <div className={cls.spendingHeader}>
          Расходы на объекте: {obj.nameObj}
        </div>
        <ul>
          <li key={'header'} className={cls.header_li}>
            <span>№</span>
            <span>число</span>
            <span>причина</span>
            <span>Сообщение</span>
            <span>Автор</span>
            <span>количество</span>
            <span>фото</span>
          </li>
          {outputItems}
        </ul>
      </div>     
    </div>
  );
}

export default ObjectPage;
