import { useState } from 'react';
import cls from './ObjectsBar.module.css'
import { fetchHandle } from '../../../api/fetch';
import { WORDS, isAdmin } from '../../../assets/functions';

function ObjectsBar(props) {
  const admin = isAdmin(props.app.userName)
  const [inputObjName, setInputObjName] = useState('')
  const [inputNumber, setInputNumber] = useState('')
  const [inputAdress, setInputAdress] = useState('')
  let mapLink = 'https://www.google.com/maps/place/'

  
  const pressOnObjectName = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    props.setCurrentObject(id)
    fetchHandle('GET', props.addSalaryInObjectSpending, 'objecthours',undefined, '/' + id)

    props.changeAppState('OBJECTPAGE')
  }

  const presAddObject = () => {
    let newObject = {
      id: new Date().getTime(), 
      nameObj: inputObjName, 
      number: inputNumber,
      adress: inputAdress, 
      archive: false,
      spanding:[],
      color: '#'+(Math.random() * 0x1000000 | 0x1000000).toString(16).slice(1)
  
    }
    fetchHandle('POST', props.addNewObject, 'objects', newObject)
  }

  const presArchiveObject = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    fetchHandle('POST', props.archiveObject, 'objectsarchive', {id})
  }
    let objectsOutput = props.objects.objects.map((el,idx) => {
    if(el.archive){
      
    }
    let adress = ''
    if(el.adress){
      adress = `${mapLink}${el.adress.split(' ').join('+')}`
    }
    return <li className={`${idx%2 === 0 ? cls.grey: null} ${el.archive ? cls.archive : null}`} key={idx}>
              <div data-id={el.id} onClick={admin ? (e) => pressOnObjectName(e) : null}>
                <span className={cls.numberSpan}>{idx + 1}.) </span>
                { admin 
                ? <span data-id={el.id}  onClick={(e) => {presArchiveObject(e)}} className={cls.archiveSpan}>
                    Arkisto
                  </span> 
                : null}
                <span className={cls.spanColor} style={{background: `${el.color}`}}></span>
                {el.nameObj} - {el.adress}
              </div>
                {adress !== ''
                ? <span className={cls.plceWrapper}>
                    <a href={adress}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g>
                          <path d="M12,2A7.008,7.008,0,0,0,5,9c0,5.353,6.036,11.45,6.293,11.707l.707.707.707-.707C12.964,20.45,19,14.353,19,9A7.008,7.008,0,0,0,12,2Zm0,16.533C10.471,16.825,7,12.553,7,9A5,5,0,0,1,17,9C17,12.546,13.527,16.823,12,18.533Z"/>
                          <path d="M12,6a3,3,0,1,0,3,3A3,3,0,0,0,12,6Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,10Z"/>
                        </g>
                      </svg>
                    </a>
                  </span>
                  : null
              }
              
              {/* <span onClick={() => presDeleteObject(el.id) } className={cls.cancel}>x</span> */}
            </li>
  })
  
  return (
    <div className={cls.wrapper}>
     { admin 
      ? <div className={cls.inputWrapper}>
          <input placeholder={WORDS.nameOfObject[props.app.lang]} onChange={(e) => setInputObjName(e.currentTarget.value)} type="text" />
          <input placeholder={WORDS.nuumberOfObject[props.app.lang]} onChange={(e) => setInputNumber(e.currentTarget.value)} type="text" />
          <input placeholder={WORDS.adresOfObject[props.app.lang]} onChange={(e) => setInputAdress(e.currentTarget.value)} type="text" />
          <button onClick={(e) => presAddObject()} className={cls.btnCancel}>{WORDS.add[props.app.lang]}</button>
        </div>
        : null}
      <ul className={cls.listWrapper}>
        {objectsOutput}
      </ul>
    </div>
  );
}

export default ObjectsBar;
