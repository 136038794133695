import cls from './Hours.module.css'
import DayOfWeek from '../DayOfWeek/DayOfWeek';
import { sortWeekByDay } from '../../assets/functions';

function Hours(props) {
  let sortArrWeek = sortWeekByDay(props.hours.week)
  let output = sortArrWeek.map((el,idx) => {
    return <DayOfWeek day={el} 
                      userName={props.app.userName}
                      key={idx}
                      app={props.app}
                      idx={idx}
                      users={props.users}
                      addNewWorkItem={props.addNewWorkItem}
                      deleteWorkItem={props.deleteWorkItem}
                      weekNumber={props.weekNumber}
                      inputHandleWeek={props.inputHandleWeek}
                      objects={props.objects}
                      
                      />
  })
  return (
    <div className={cls.wrapper}>
        {output}
    </div>
  );
}

export default Hours;
