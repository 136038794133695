const INIT_FILTER = 'INIT_FILTER'
const initState = {
  filter:[],
}


const usersReducer = (state = initState, action) => {  
    switch(action.type){
        case INIT_FILTER:    
            return {...state,filter:[...action.value]}
        default:
            return state
    }
}
export const initFilter = (value) => ({ type:INIT_FILTER, value})
export default usersReducer