const CHANGE_NEW_PRODUCT_INPUT = 'CHANGE_NEW_PRODUCT_INPUT'
const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT'
const INIT_GOODS = 'INIT_GOODS'
const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
const DELETE_PRODUCT = 'DELETE_PRODUCT'

const initState = {
    goods:[],
    newProductinput:''
  }


const goodsReducer = (state = initState, action) => {  
    switch(action.type){
        case UPDATE_PRODUCT:    
            let newGoods = [...state.goods]
            let index = newGoods.indexOf(action.product)
            let newStatus = action.product.status === 'pending' ? 'finished' : 'pending'
            newGoods[index] = {...action.product, status:newStatus}
            return {...state,goods:newGoods}

        case CHANGE_NEW_PRODUCT_INPUT:    
            return {...state, newProductinput:action.value}
         
        case ADD_NEW_PRODUCT:
            return {...state, goods:[...state.goods,action.product], newProductinput:''}

        case INIT_GOODS:    
            return {...state, goods:action.goods}
         
        case DELETE_PRODUCT:    
            let newGoods1 = state.goods.filter((product) => {
                return +product.id !== +action.product.id
            })
            return {...state, goods:newGoods1}
         
        default:
            return state
    }
}
export const changeValue = (value) => ({type:CHANGE_NEW_PRODUCT_INPUT, value})
export const addNewProduct = (product) => ({type:ADD_NEW_PRODUCT, product})
export const initGoods = (goods) => ({type:INIT_GOODS,goods})
export const updateProduct = (product) => ({type:UPDATE_PRODUCT, product})
export const deleteProduct = (product) => ({type:DELETE_PRODUCT, product})
export default goodsReducer