import { useState } from 'react';
import cls from './Filter.module.css'

import ExcelJS from "exceljs";
import { WORDS, excelCells, getDate, getDateInputValueFromTimestamp, getFullNameByName, getHoursAnding, getHoursAndingFI, getObjectByID, getObjectColorByID, getTimestampFromInputValue, getTimestampFromdayAndWeek, nameOfdaysWeek, nameOfdaysWeekFi, sortHoursbyWeeks, sortWeekByDay } from '../../assets/functions';
import { fetchHandle } from '../../api/fetch';
import PORT from '../../api/config';
import moment from 'moment';

function Filter(props) {
  console.log('props: ', props);

  const [startDate, setStartDate] = useState(0)
  const [finishDate, setFinishDate] = useState(0)
  const [workerName, setWorkerName] = useState(0)
  const [code, setCode] = useState(WORDS.all[props.app.lang])
  const [objID, setObjID] = useState(0)
  const [hide, setHide] = useState('none') // show/hide days in weeks
  // const [lastColumn, setLastColumn] = useState(0)
  // const [superTotal, setSuperTotal] = useState(0)

  const clickOnTyöntekijäButton = () => {
    fetch(PORT + 'filter/' + workerName + '/' + startDate + '/' + finishDate + '/' + objID + '/' + code, {
      method: "GET",
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    })
      .catch((err) => {
        console.log(err)
      })
      .then((response) => {
        return response.text()
      })
      .then((data) => {
        let hoursArr = JSON.parse(data)
        return hoursArr
      })
      .then((hoursArr) => {
        let countOfDays = ((finishDate - startDate) / 86400) + 1 //counts days in period
        let startDayInWeek = moment(startDate * 1000).isoWeekday()
        let finishtDayInWeek = moment(finishDate * 1000).isoWeekday()
        let currentListOfUsersArr = []
        let lastColumn = countOfDays
        let hourSum = 0
        let dinnerSum = 0
        const sortedHoursByWeek = hoursArr.reduce((acc, obj) => { //sorted by week
          const weekNumber = obj.weekNumber;
          // Если ключа с таким именем еще нет, создаем новый массив
          if (!acc[weekNumber]) {
            acc[weekNumber] = [];
          }
          // Добавляем текущий объект в массив для данного ключа
          acc[weekNumber].push(obj);
          return acc;
        }, {});
        let countsOfWeeks = Object.keys(sortedHoursByWeek).length

        for (let key in sortedHoursByWeek) {  //sorted by Name
          let sortWeek = sortedHoursByWeek[key].reduce((acc, obj) => {
            const userName = getFullNameByName(obj.userName, props.users);
            // Если ключа с таким именем еще нет, создаем новый массив
            if (!acc[userName]) {
              acc[userName] = [];
            }
            // Добавляем текущий объект в массив для данного ключа
            acc[userName].push(obj);
            return acc;
          }, {});
          sortedHoursByWeek[key] = sortWeek
        }

        const workbook = new ExcelJS.Workbook();
        let nameExcel = `${getDateInputValueFromTimestamp(startDate)} - ${getDateInputValueFromTimestamp(finishDate)}`
        workbook.addWorksheet(nameExcel);
        for (let key in sortedHoursByWeek) { // create array with uniq users in period
          let keys = Object.keys(sortedHoursByWeek[key])
          currentListOfUsersArr.push(...keys)
        }

        const currentListOfUsers = currentListOfUsersArr.reduce((acc, item) => {
          if (acc.includes(item)) {
            return acc;
          }
          return [...acc, item];
        }, []);
        currentListOfUsers.sort((a, b) => a > b ? 1 : -1)
        let countOfUsersRows = currentListOfUsers.length * 2

        const worksheet = workbook.getWorksheet(nameExcel);

        for (let i = 0; i < countOfDays; i++) {// add numbers and names of days in table
          let date = moment(startDate * 1000).add(i, 'days')
          let weekend = date.isoWeekday() > 5 ? true : false
          let dateCell = worksheet.getCell(`${excelCells[i + 1]}2`)
          let dayOfWeekCell = worksheet.getCell(`${excelCells[i + 1]}3`)
          let column = worksheet.getColumn(i + 2)
          column.width = '5px'

          dayOfWeekCell.value = `${nameOfdaysWeekFi[date.isoWeekday() - 1].substr(0, 2)}`
          dateCell.value = `${date.date()}.${date.month() + 1}`

          dateCell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'thin' }, right: { style: 'medium' } };
          dayOfWeekCell.border = { top: { style: 'thin' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };

          dateCell.alignment = { vertical: 'middle', horizontal: 'center' };
          dayOfWeekCell.alignment = { vertical: 'middle', horizontal: 'center' };


          if (weekend) { // paint cells on weekends
            for (let j = 1; j < +countOfUsersRows + 3; j++) {
              worksheet.getCell(`${excelCells[i + 1]}${j}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'f3aa0c' } } //add BGcolor for wekendsDays
            }

          }

        }
        const hoursObjSortByName = hoursArr.reduce((acc, obj) => { //sorted by week
          const userName = obj.userName;
          // Если ключа с таким именем еще нет, создаем новый массив
          if (!acc[userName]) {
            acc[userName] = [];
          }
          // Добавляем текущий объект в массив для данного ключа
          acc[userName].push(obj);
          return acc;
        }, {});
        let sortHoursByNameAndAlphabet = {}


        for (const name in hoursObjSortByName) { //change key from 'name' to 'surname name'
          for (let i = 0; i < props.users.length; i++) {
            if (props.users[i].name === name) {
              let fullName = `${props.users[i].surname} ${props.users[i].name}`
              sortHoursByNameAndAlphabet[fullName] = hoursObjSortByName[name]
            }
          }
        }


        let inputHours = {} // create obj with key:user value: array on period with count of hours per day
        for (const user in sortHoursByNameAndAlphabet) {
          let arr = new Array(countOfDays)
          arr.fill(0)
          sortHoursByNameAndAlphabet[user].forEach(obj => {
            let hourTime = Math.round((moment(obj.timestampOfDay * 1000) - moment(startDate * 1000)) / 86400000)

            arr[hourTime] += obj.time
          });
          inputHours[user] = arr

        }


        let arrHeader = new Array(countOfDays)// create arr with header
        arrHeader.fill({ width: 7 }) //add width 
        worksheet.columns = [{ header: 'Työntekijä', key: 'Työntekijä', width: 20 }, ...arrHeader]; //add works column

        worksheet.mergeCells('A1:A3')
        worksheet.mergeCells(`${excelCells[lastColumn + 1]}1:${excelCells[lastColumn + 1]}3`)
        // worksheet.mergeCells(`${excelCells[lastColumn+2]}1:${excelCells[lastColumn+2]}3`)

        worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('A3').border = { bottom: { style: 'medium' }, right: { style: 'medium' } };
        worksheet.getCell(`${excelCells[lastColumn + 1]}3`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`${excelCells[lastColumn + 1]}3`).border = { bottom: { style: 'medium' }, right: { style: 'medium' } };
        worksheet.getCell(`${excelCells[lastColumn + 2]}3`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`${excelCells[lastColumn + 2]}3`).border = { bottom: { style: 'medium' }, right: { style: 'medium' } };


        let allHoursCell = worksheet.getCell(`${excelCells[+lastColumn + 2]}:3`)
        allHoursCell.border = { top: { style: 'thin' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        allHoursCell.alignment = { vertical: 'middle', horizontal: 'center' };

        let arrWithHeaders = [
          'Yhteensa',
          'Työpäivät',
          '',
          'Ateriakorvaus 12€/pv',
          'Tuntipalkka',
          'BRUTTO palkka',
          'Palkkiopalkka, €',
          'Soveletun tunnin palkka',
          'Soveletut työtunnit',
          '',
        ]
        for (let i = 0; i < 9; i++) {
          worksheet.mergeCells(`${excelCells[lastColumn + 2 + i]}1:${excelCells[lastColumn + 2 + i]}3`)
          let allHoursCell = worksheet.getCell(`${excelCells[+lastColumn + 2 + i]}:3`)
          allHoursCell.border = { top: { style: 'thin' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
          allHoursCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          let cell = worksheet.getColumn(+lastColumn + 2 + i)
          cell.header = arrWithHeaders[i]
          cell.key = arrWithHeaders[i]
          cell.width = 12
        }


        for (let i = 0; i < countsOfWeeks; i++) { // add name for weeks
          if (i === 0) { //first week
            worksheet.mergeCells(`'${excelCells[1]}1:${excelCells[8 - startDayInWeek]}1'`);
            let numberOfWeek = +Object.keys(sortedHoursByWeek)[i]
            let cellOfWeek = worksheet.getCell(`'${excelCells[7 - startDayInWeek]}1'`)
            cellOfWeek.value = `vko ${numberOfWeek}`
            cellOfWeek.alignment = { vertical: 'middle', horizontal: 'center' };
            cellOfWeek.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
          }
          else if (i === countsOfWeeks - 1) { //last week
            worksheet.mergeCells(`'${excelCells[(9 - startDayInWeek) + 7 * (i - 1)]}1:${excelCells[(9 - startDayInWeek + finishtDayInWeek - 1) + 7 * (i - 1)]}1'`);
            let numberOfWeek = +Object.keys(sortedHoursByWeek)[i]
            let cellOfWeek = worksheet.getCell(`'${excelCells[(9 - startDayInWeek + finishtDayInWeek - 1) + 7 * (i - 1)]}1'`)
            cellOfWeek.value = `vko ${numberOfWeek}`
            cellOfWeek.alignment = { vertical: 'middle', horizontal: 'center' };
            cellOfWeek.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
          }
          else { //middle weeks
            worksheet.mergeCells(`'${excelCells[(9 - startDayInWeek) + 7 * (i - 1)]}1:${excelCells[(15 - startDayInWeek) + 7 * (i - 1)]}1'`);
            let numberOfWeek = +Object.keys(sortedHoursByWeek)[i]
            let cellOfWeek = worksheet.getCell(`'${excelCells[(15 - startDayInWeek) + 7 * (i - 1)]}1'`)
            cellOfWeek.value = `vko ${numberOfWeek}`
            cellOfWeek.alignment = { vertical: 'middle', horizontal: 'center' };
            cellOfWeek.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
          }
        }


        for (let i = 0; i < currentListOfUsers.length; i++) { // add user name in table
          let cell1 = worksheet.getCell(`A${i + 4 + i}`)
          let cell2 = worksheet.getCell(`A${i + 5 + i}`)
          let row1 = worksheet.getRow(`${i + 4 + i}`)
          let row2 = worksheet.getRow(`${i + 5 + i}`)
          for (let j = 1; j < (+lastColumn + 12); j++) {
            row1.getCell(j).border = { right: { style: 'thin' } }
            row2.getCell(j).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fde67d' } }
            row2.getCell(j).border = { right: { style: 'thin' } }
          }

          cell1.value = currentListOfUsers[i]
          cell2.value = currentListOfUsers[i]
        }
        for (let i = 4; i < countOfUsersRows + 4; i++) { //add rightborder for usersName
          worksheet.getCell(`A${i}`).border = { right: { style: 'medium' } };
        }




        for (let i = 0; i < countOfUsersRows; i++) {//add style for each cells
          let row = worksheet.getRow(`${i + 4}`)
          for (let k = 0; k < lastColumn; k++) {
            let cell = row.getCell(k + 2)
            cell.border = { right: { style: 'thin' } }
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        }

        let keysOfHours = Object.keys(inputHours).sort() //sort keys of obkect by alphabit


        for (let i = 0; i < currentListOfUsers.length; i++) {// add hours
          let row = worksheet.getRow(`${i + 4 + i}`)
          let total = 0
          let userName = keysOfHours[i]
          row.getCell(1).value = userName
          for (let j = 0; j < +lastColumn; j++) { // add value in cells
            let dayHours = inputHours[keysOfHours[i]][j]
            let cell = row.getCell(j + 2)
            cell.border = { right: { style: 'thin' } }
            if (dayHours !== 0) {
              total += dayHours > 6 ? dayHours - 0.5 : dayHours //minus dinner COUNT SUM
              cell.value = dayHours > 6 ? dayHours - 0.5 : dayHours //minus dinner

            }
          }
          hourSum += total

          let arrWorkDays = inputHours[keysOfHours[i]].filter(element => element > 6)
          dinnerSum += arrWorkDays.length

          let hCell = row.getCell(+lastColumn + 2) //count hours sum in row
          hCell.value = total
          hCell.border = { right: { style: 'thin' } };
          hCell.alignment = { vertical: 'middle', horizontal: 'center' };

          let wCell = row.getCell(+lastColumn + 3)
          wCell.value = arrWorkDays.length //add count of works day
          wCell.border = { right: { style: 'thin' } };
          wCell.alignment = { vertical: 'middle', horizontal: 'center' };


        }
        let lastSumRow = worksheet.getRow(currentListOfUsers.length * 2 + 4)
        let totalLastRow = worksheet.getRow(currentListOfUsers.length * 2 + 5)

        let hoursSumCell = lastSumRow.getCell(lastColumn + 2)
        hoursSumCell.value = hourSum
        hoursSumCell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        hoursSumCell.alignment = { vertical: 'middle', horizontal: 'center' };

        let countOfdaysCell = lastSumRow.getCell(lastColumn + 3)
        countOfdaysCell.value = dinnerSum
        countOfdaysCell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        countOfdaysCell.alignment = { vertical: 'middle', horizontal: 'center' };

        let lastCommonSum = totalLastRow.getCell(lastColumn + 2)
        lastCommonSum.value = hourSum + dinnerSum * 0.5
        lastCommonSum.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        lastCommonSum.alignment = { vertical: 'middle', horizontal: 'center' };

        return workbook.xlsx.writeBuffer()
      })
      .then((uint8Array) => {
        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `period_${getDateInputValueFromTimestamp(startDate)} - ${getDateInputValueFromTimestamp(finishDate)}.xlsx`;
        a.click();
        a.remove();
      })
  }

  const clickOnWeek = (hide) => {
    hide === 'block'
      ? setHide('none')
      : setHide('block')
  }


  let selectOutputWorkers = []
  selectOutputWorkers = props.users.map((el, idx) => {
    return <option key={idx} value={el.name}>{el.surname} {el.name}</option>
  })
  selectOutputWorkers.unshift(<option key={657657657657} value={0}>{WORDS.all[props.app.lang]}</option>)
  const groupedData = sortHoursbyWeeks(props.filter)

  let selectOutputObjects = []
  let filterObjects = props.objects.objects.filter((obj) => !obj.archive) // sorting filter by archive
  selectOutputObjects = filterObjects.map((el, idx) => {
    return <option key={idx} value={el.id}>{el.nameObj}</option>
  })
  selectOutputObjects.unshift(<option key={243523} value={0}>{WORDS.all[props.app.lang]}</option>)

  let listOutput = []
  let totalcountTime = 0
  for (let key in groupedData) {
    let daysArr = []
    const week = groupedData[key];
    let sortWeek = sortWeekByDay(week)
    let weekCounterTime = 0
    for (let i = 0; i < sortWeek.length; i++) {
      let NameOfDay = props.app.lang === 'FI' ? nameOfdaysWeekFi[i] : nameOfdaysWeek[i]
      let dayCounterTime = 0
      let dayOutput = sortWeek[i].map((el, idx) => {
        dayCounterTime += el.time
        let color = { background: getObjectColorByID(el.objID, props.objects.objects) }
        return <li style={{ display: hide }} className={`${cls.hour} ${idx % 2 !== 0 ? cls.grey : null}`} key={el.id}>
          {idx + 1}.) |{el.userName}| {el.workName} - {el.time}
          <span style={color} className={cls.objectSpan}>{getObjectByID(el.objID, props.objects.objects)}</span>
          {el.code
            ? <span style={color} className={cls.objectSpan}>{el.code}</span>
            : null
          }
        
        </li>
      })
      if (dayCounterTime > 0) {
        daysArr.push(<li className={cls.nameOfDay} style={{ display: hide }}>
          {getDate(key, i)} - {NameOfDay} - <span className={cls.counterDayTime}>{dayCounterTime}</span> {props.app.lang === 'FI' ? getHoursAndingFI(dayCounterTime) : getHoursAnding(dayCounterTime)}
        </li>)
        daysArr.push(...dayOutput)
      }
      weekCounterTime += dayCounterTime
    }
    listOutput.push(<li onClick={() => clickOnWeek(hide)} className={cls.numberOfWeek}>{WORDS.week[props.app.lang]}: {key} | {weekCounterTime} {props.app.lang === 'FI' ? getHoursAndingFI(weekCounterTime) : getHoursAnding(weekCounterTime)}</li>)
    listOutput.push(...daysArr)
    totalcountTime += weekCounterTime
  }
  listOutput.push(<li>{WORDS.total[props.app.lang]}: {totalcountTime} {props.app.lang === 'FI' ? getHoursAndingFI(totalcountTime) : getHoursAnding(totalcountTime)}</li>)


  const clickOnFilterButton = () => {
    fetchHandle('GET', props.initFilter, 'filter/', undefined, workerName + '/' + startDate + '/' + finishDate + '/' + objID + '/' + code)
  }



  const changeStartInput = (e) => {
    setStartDate(getTimestampFromInputValue(e.currentTarget.value));
  }

  const changeFinishInput = (e) => {
    setFinishDate(getTimestampFromInputValue(e.currentTarget.value));
  }

  const changeWorkerNameInput = (e) => {
    setWorkerName(e.currentTarget.value);
  }

  const changeObjIDInput = (e) => {
    setObjID(e.currentTarget.value);
  }
  const changeCodeInput = (e) => {
    setCode(e.currentTarget.value);
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.header}>
        <span className={cls.label}>{WORDS.from[props.app.lang]}</span>
        <input onChange={(e) => { changeStartInput(e) }} className={cls.field} type="date" />
        <span className={cls.label}>{WORDS.to[props.app.lang]}</span>
        <input onChange={(e) => { changeFinishInput(e) }} className={cls.field} type="date" />
        <span className={cls.label}>{WORDS.worker[props.app.lang]}</span>
        <select onChange={(e) => { changeWorkerNameInput(e) }} className={cls.field}>
          {selectOutputWorkers}
        </select>
        <span className={cls.label}>{WORDS.object[props.app.lang]}</span>
        <select onChange={(e) => { changeObjIDInput(e) }} className={cls.field}>
          {selectOutputObjects}
        </select>
        <span className={cls.label}>{WORDS.code[props.app.lang]}</span>
        <input onChange={(e) => { changeCodeInput(e) }} value={code}  className={cls.field} type="text" />
        <button onClick={() => { clickOnFilterButton() }} className={cls.field}>{WORDS.filtering[props.app.lang]}</button>
        <button onClick={() => { clickOnTyöntekijäButton() }} className={cls.field}>Excel työntekijä</button>
      </div>
      <div className={cls.list}>
        <ul>
          {listOutput}
        </ul>
      </div>
    </div>
  );
}

export default Filter;
