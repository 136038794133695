import { createStore, combineReducers } from 'redux'
import {reducer as formReducer} from 'redux-form';
import goodsReducer from './goodsReducer.js'
import appReducer from './appReducer.js'
import tasksReducer from './tasksReducer.js'
import hoursReducer from './hoursReducer.js'
import objectsReducer from './objectsReducer.js'
import usersReducer from './usersReducer.js'
import filterReducer from './filterReducer.js'
 

let reducers = combineReducers({
  goods: goodsReducer,
  app: appReducer,
  tasks: tasksReducer,
  hours: hoursReducer,
  objects: objectsReducer,
  users: usersReducer,
  filter: filterReducer,
  
})
let store = createStore(reducers)

window.store = store
export default store  