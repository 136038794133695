import { useState } from 'react'
import { WORDS, getUserByName, isAdmin } from '../../assets/functions'
import cls from './HeaderButtons.module.css'

function HeaderButtons(props) {
  let admin = isAdmin(props.userName)
  
  const[isMobile, setIsMobile] = useState(window.innerWidth < 500 ? true : false)
  const[hideMenu, setHideMenu] = useState(isMobile)

  const buttonGoodsHandler = () => {
    props.changeAppState('GOODS')
  }
  const buttonTasksHandler = () => {
    props.changeAppState('TASKS')
  }
  const buttonHoursHandler = () => {
    props.changeAppState('HOURS')
    if(isMobile) setHideMenu(true)
  }
  const buttonLangHandler = (e) => {
    let lang = e.currentTarget.getAttribute('lang')
    if(isMobile) setHideMenu(true)
    props.setLang({lang})
  }
  const buttonObjkectsHandler = () => {
    props.changeAppState('OBJECTS')
    if(isMobile) setHideMenu(true)
  }
  const buttonFilterHandler = () => {
    props.changeAppState('FILTER')
    if(isMobile) setHideMenu(true)
  }
  const buttonProfileHandler = () => {
    let user = getUserByName(props.users.users, props.app.userName)
    props.initCurrentUser(user.id)
    props.changeAppState('PROFILE')
    if(isMobile) setHideMenu(true)
  }
  const clickOnBurger = () => {
    console.log('clickOnBurger: ', clickOnBurger);
    setHideMenu(!hideMenu)
  }
  return (
    <div className={cls.wrapper}>
      <div className={cls.burger} onClick={() => {clickOnBurger()}}>
        <span className={cls.span}></span>
        <span className={cls.span}></span>
        <span className={cls.span}></span>
      </div>
      <div className={`${cls.menu} ${hideMenu ? cls.displayNone : null}`}>
        {admin
          ? 
            <div onClick={buttonFilterHandler} className={cls.button}>
              <span>{WORDS.filter[props.app.lang]}</span>
            </div>
          : null}

        <div onClick={buttonLangHandler} lang={props.app.lang} className={cls.button}>
          <span>{props.app.lang}</span>
        </div>
        <div onClick={buttonHoursHandler} className={cls.button}>
          <span>{WORDS.hours[props.app.lang]}</span>
        </div>
        <div onClick={buttonObjkectsHandler} className={cls.button}>
          <span>{WORDS.objects[props.app.lang]}</span>
        </div>
        {/* <div onClick={buttonTasksHandler} className={cls.button}>
          <span>{WORDS.tasks[props.app.lang]}</span>
        </div> */}
        <div onClick={buttonProfileHandler} className={cls.button}>
          <span>{WORDS.profile[props.app.lang]}</span>
        </div>
      </div>
    </div>
  );
}

export default HeaderButtons;
