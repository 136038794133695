import {
    connect
} from 'react-redux'
import Header from './Header.jsx'
import {
    changeAppState,
    setUserName,
    setLang
} from '../../redux/appReducer.js'
import {
    initHours,
    setInputHandleWeek
} from '../../redux/hoursReducer.js'
import {
    initCurrentUser
} from '../../redux/usersReducer.js'


let mapStateToProps = (state) => {
    return {
        app: state.app,
        users: state.users,
        week: state.hours.week,
        currentYear: state.hours.currentYear,
        weekNumber: state.hours.currentNumberWeek


    }
}
let mapDispatchToProps = (dispatch) => {
    return {
        ///--------------APP-------------

        changeAppState: (value) => {
            dispatch(changeAppState(value))
        },

        setUserName: (value) => {
            dispatch(setUserName(value))
        },

        setLang: (value) => {
            dispatch(setLang(value))
        },
        ///--------------HOURS-------------

        initHours: (value) => {
            dispatch(initHours(value))
        },
        setInputHandleWeek: (value) => {
            dispatch(setInputHandleWeek(value))
        },
        ///--------------USERS-------------

        initCurrentUser: (value) => {
            dispatch(initCurrentUser(value))
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)