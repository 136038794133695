import { useEffect, useState } from 'react';
import { fetchHandle } from '../../api/fetch';
import { nameOfdaysWeek, getCurrentDay, getHoursAnding, getHoursAndingFI, getDate, getObjectByID, getIDObjectByName, getTimestampFromdayAndWeek, getObjectColorByID, WORDS, nameOfdaysWeekFi, getCodeOfObjectByName } from '../../assets/functions';
import cls from './DayOfWeek.module.css'

function DayOfWeek(props) {

  const [workItemValue, setWorkItemValue] = useState('')
  const [hoursValue, setHoursValue] = useState(0)
  const [codeValue, setCodeValue] = useState(0)
  const [objectValue, setObjectValue] = useState('GerdaA')
  const [extraWork, setExtraWork] = useState(0)




  const isCurrenWeek = props.weekNumber == props.inputHandleWeek // current Week and wathing week is eqwal 
  // const isCurrenWeek = true;console.error('change isCurrenWeek DayOfWeek');
  let filterObjects = props.objects.objects.filter((obj) => !obj.archive)
  let outputOption = filterObjects.map((el, idx) => {
    return <option value={el.nameObj} dataid={el.id} key={idx}>{el.nameObj}</option>

  })
  // if(props.users.users.length!==0 && props.objects.objects.length!==0){
  //   let user = props.users.users.find((el) => {
  //     return el.name === props.userName
  //   });

  //   }

  //   const mergedArray = [];

  //   for (const mainObj of user.objects) {
  //     const id = mainObj.id;
  //     const lookupObj = props.objects.objects.find((obj) => obj.id === id);

  //     if (lookupObj) {
  //       mergedArray.push({ ...mainObj, ...lookupObj });
  //     }
  //   }
  //   curentobjectArr =  mergedArray;
  // }
  // let outputOption
  // if(curentobjectArr){
  //   outputOption = curentobjectArr.map((el,idx) => {
  //   return <option value={el.nameObj} dataid={el.id} key={idx}></option>
  // })
  // }


  let currentDay = getCurrentDay()
  const pressOnPlus = (day) => {
    let code = getCodeOfObjectByName(props.objects.objects,objectValue) === null 
      ? null 
      : getCodeOfObjectByName(props.objects.objects,objectValue)+codeValue
    let newItem = {
      id: new Date().getTime(),
      dayOfWeek: day,
      weekNumber: props.weekNumber,
      workName: workItemValue,
      time: hoursValue,
      code: code,
      userName: props.userName,
      extraWork: extraWork,
      objID: getIDObjectByName(objectValue, props.objects.objects),
      timestampOfDay: getTimestampFromdayAndWeek(day, props.weekNumber)
    }
    fetchHandle('POST', props.addNewWorkItem, 'hours', newItem)
    setWorkItemValue('')
  }
  const pressOnCross = (id) => {
    fetchHandle('DELETE', props.deleteWorkItem, 'hours', { id })
  }
  const onChangeInput = (e) => {
    setWorkItemValue(e.currentTarget.value)
  }
  const onChangeInputNumber = (e) => {
    setHoursValue(e.currentTarget.value)
  }
  const onChangeCodeNumber = (e) => {
    setCodeValue(e.currentTarget.value)
  }
  const onChangeInputObject = (e) => {
    setObjectValue(e.currentTarget.value)
  }
  let totalHours = 0
  let output = props.day.map((workItem, idx) => {
    let color = { background: getObjectColorByID(workItem.objID, props.objects.objects) }
    totalHours += +workItem.time
    return <div key={workItem.id} className={`${cls.workItem} ${idx % 2 === 0 ? cls.workItemEven : null}`}>
      <div className={cls.hoursTextWrapper}>
        <span>
          {idx + 1}) {workItem.workName} - {workItem.time} {props.app.lang === 'FI' ? getHoursAndingFI(workItem.time) : getHoursAnding(workItem.time)}
        </span>
        <br />
        <span style={color} className={cls.objectSpan}>
          {getObjectByID(workItem.objID, props.objects.objects)}
        </span>
        {workItem.code 
        ? <span style={color} className={cls.objectSpan}>{workItem.code}</span>
        : null}
      </div>
      <div className={cls.buttonWrapper}>
        {isCurrenWeek
          ? <div className={cls.button} onClick={() => { pressOnCross(workItem.id) }}>
            <span className={cls.iconCross}></span>
            <span className={`${cls.iconCross} ${cls.iconCross_rotate}`}></span>
          </div>
          : null}

      </div>

    </div>
  })

  return (
    <li className={`${props.idx < 5 ? cls.wrapper : cls.wrapper + ' ' + cls.holiday}`}>
      <p className={cls.header}>
        <span className={currentDay === props.idx && isCurrenWeek ? cls.today : ''}>
          {props.app.lang === 'FI' ? nameOfdaysWeekFi[props.idx] : nameOfdaysWeek[props.idx]}
          <span> - </span>
          {getDate(props.inputHandleWeek, props.idx)}
          {currentDay === props.idx && isCurrenWeek ? ` - ${WORDS.today[props.app.lang]}` : null}
        </span>
      </p>
      {output}
      <div className={cls.countHours}>
        <span>{WORDS.countOfHours[props.app.lang]}: </span>
        <span className={cls.numberOfCount}>{totalHours}</span>
      </div>
      {isCurrenWeek
        ? <div className={cls.listWrapper}>
          <div className={cls.enterWrapper}>
            <div className={cls.inputWrapper}>
              <span className={cls.labelInput}>{WORDS.kindOfJob[props.app.lang]}:</span>
              <input onChange={(e) => { onChangeInput(e) }} className={cls.input} value={workItemValue} type="text" /></div>

          </div>
          <div className={cls.inputWrapper}>
            <span className={cls.labelInput}>{WORDS.object[props.app.lang]}:</span>
            <select className={cls.input} value={objectValue} onChange={(e) => { onChangeInputObject(e) }}>
              {outputOption}
            </select>
            {/* <input onChange={(e) => {onChangeInputObject(e)}}  list='objects' className={cls.input} value={objectValue} type="text" />
            <datalist id='objects'>
            {outputOption}
            </datalist> */}
          </div>
          
          <div className={cls.checkboxWrapper}>
            {/* <span className={cls.hourSpan}>Доп/раб</span>
          <input className={cls.checkbox_ExtraWork} type="checkbox" value={extraWork} onClick={() => {setExtraWork(extraWork === 0 ? 1 : 0)}} /> */}
            <div className={cls.hoursWrapper}>
              <span className={cls.hourSpan}>{WORDS.hours[props.app.lang]}:</span>
              <input onChange={(e) => { onChangeInputNumber(e) }} className={cls.inputNumber} value={hoursValue} type="number" />
            </div>
            <div className={cls.hoursWrapper}>
              <span className={cls.hourSpan}>{WORDS.code[props.app.lang]}:</span>
              <input onChange={(e) => { onChangeCodeNumber(e) }} className={cls.inputNumber} value={codeValue} type="number" />
            </div>
            <div className={cls.buttonPlus} onClick={() => { pressOnPlus(props.idx) }}>
              <span className={cls.iconPlus}>&#10133; </span>
              <span>{WORDS.add[props.app.lang]}</span>
            </div>
          </div>
        </div>
        : null}

    </li>
  );
}

export default DayOfWeek;
