import { useState } from 'react';
import cls from './UsersBar.module.css'
import { fetchHandle } from '../../../api/fetch';
import { WORDS, isAdmin } from '../../../assets/functions';

function UsersBar(props) {
  
  const [inputUserName, setInputUserName] = useState('')
  const [inputUserSurname, setInputUserinputUserSurname] = useState('')
  const [inputPass, setInputPass] = useState('')
  const [inputPhone, setInputPhone] = useState('')

  const admin = isAdmin(props.app.userName)

  const pressAddUser = (nameUser) => {
    let newUser = {
      id: new Date().getTime(), 
      name: inputUserName, 
      surname: inputUserSurname, 
      password: inputPass,
      phone: inputPhone,
      clothes:{
        shirt:'',
        pants:'',
        shose:'',
    }
    }
    fetchHandle('POST', props.addNewUser, 'users', newUser)
  }
  const pressUser = (userID) => {
    props.initCurrentUser(userID)
    props.changeAppState('PROFILE')
  }

  // const presArchiveObject = (id) => {
  //   fetchHandle('PUT', props.deleteUser, 'users', {id})
  // }
  let  usersOutput = props.users.users.map((el,idx) => {
    return <li onClick={()=>{pressUser(el.id)}} 
                className={idx%2 === 0 ? cls.grey: null} 
                key={idx}>
              <div>
                {idx + 1}.) {el.surname} {el.name}
              </div>
            </li>
  })
  
  return (
    <div className={cls.wrapper}>
      {admin 
      ?<div className={cls.inputWrapper}>
      <input placeholder={WORDS.name[props.app.lang]} onChange={(e) => setInputUserName(e.currentTarget.value)} type="text" />
      <input placeholder={WORDS.surname[props.app.lang]} onChange={(e) => setInputUserinputUserSurname(e.currentTarget.value)} type="text" />
      <input placeholder={WORDS.phone[props.app.lang]} onChange={(e) => setInputPhone(e.currentTarget.value)} type="text" />
      <input placeholder={WORDS.pass[props.app.lang]} onChange={(e) => setInputPass(e.currentTarget.value)} type="text" />
      <button onClick={(e) => pressAddUser()} className={cls.btnCancel}>{WORDS.add[props.app.lang]}</button>
    </div>
    : null}
      
      <ul className={cls.listWrapper}>
        {usersOutput}
      </ul>
    </div>
  );
}

export default UsersBar;
