const INIT_TASKS = 'INIT_TASKS'
const CHANGE_NEW_TASK_INPUT = 'CHANGE_NEW_TASK_INPUT'
const UPDATE_TASK = 'UPDATE_TASK'
const DELETE_TASK = 'DELETE_TASK'
const ADD_NEW_TASKS = 'ADD_NEW_TASKS'

const initState = {
    tasks:[],
    newTaskInput:''
  }


const tasksReducer = (state = initState, action) => {  
    switch(action.type){
        case CHANGE_NEW_TASK_INPUT:    
            return {...state, newTaskInput:action.value}

        case UPDATE_TASK:    
            let newTasks = [...state.tasks]
            let index = newTasks.indexOf(action.product)
            let newStatus = action.product.status === 'pending' ? 'finished' : 'pending'
            newTasks[index] = {...action.product, status:newStatus}
            return {...state,tasks:newTasks}

        case INIT_TASKS:    
            return {...state, tasks:action.tasks}
        
        case ADD_NEW_TASKS:
            return {...state, tasks:[...state.tasks,action.task], newTaskInput:''}
        
        case DELETE_TASK:    
            let newGoods1 = state.tasks.filter((product) => {
                return +product.id !== +action.product.id
            })
            return {...state, tasks:newGoods1}

        default:
            return state
    }
}
export const initTasks = (tasks) => ({type:INIT_TASKS, tasks})
export const updateTask = (product) => ({type:UPDATE_TASK, product})
export const deleteTask = (product) => ({type:DELETE_TASK, product})
export const changeTaskValue = (value) => ({type:CHANGE_NEW_TASK_INPUT, value})
export const addNewTask = (task) => ({type:ADD_NEW_TASKS, task})
export default tasksReducer